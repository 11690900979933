import { amend } from "@/utils/func.js";
const colorList = ["#9E87FF", "#73DDFF", "#fe9a8b", "#F56948", "#9E87FF"];
let option = {
  backgroundColor: "#fff",
  title: {
    text: "全国6月销售统计",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
    },
    left: "center",
    top: "5%",
  },
  legend: {
    icon: "circle",
    bottom: "0%",
    right: "center",
    itemWidth: 6,
    itemGap: 20,
    textStyle: {
      color: "#556677",
    },
  },
  tooltip: {
    trigger: "axis",
    // axisPointer: {
    //   label: {
    //     show: true,
    //     backgroundColor: "#fff",
    //     color: "#556677",
    //     borderColor: "rgba(0,0,0,0)",
    //     shadowColor: "rgba(0,0,0,0)",
    //     shadowOffsetY: 0,
    //   },
    //   lineStyle: {
    //     width: 0,
    //   },
    // },
    backgroundColor: "#fff",
    textStyle: {
      color: "#5c6c7c",
    },
    padding: [10, 10],
    extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
  },
  grid: {
    top: "15%",
  },
  xAxis: {
    type: "category",
    data: ["北京", "上海", "广州", "深圳", "香港", "澳门", "台湾"],
    axisLine: {
      show: true,
      lineStyle: {
        color: "#f4f4f4",
      },
    },
    splitLine: {
      show: true,
      color: "#f0f0f0",
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
    },
    axisLabel: {
      // interval: 0,
      color: "#556677",
      // 默认x轴字体大小
      fontSize: 12,
      // margin:文字到x轴的距离
      margin: 30,
      align: "center",
      rotate: 20,
    },
    axisPointer: {
      label: {
        padding: [0, 0, 10, 0],
        margin: 15,
        fontSize: 12,
        backgroundColor: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#fff", // 0% 处的颜色
            },
            {
              offset: 0.86,
              color: "#fff", // 0% 处的颜色
            },
            {
              offset: 0.86,
              color: "#33c0cd", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#33c0cd", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    },

    boundaryGap: false,
  },

  yAxis: {
    type: "value",
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#DCE2E8",
      },
    },
    axisLabel: {
      color: "#556677",
    },
    // 分割线
    splitLine: {
      lineStyle: {
        type: "dashed",
        color: "#E9E9E9",
      },
    },
  },
  series: [
    {
      name: "Adidas",
      type: "line",
      data: [],
      symbolSize: 1,
      symbol: "circle",
      smooth: true,
      yAxisIndex: 0,
      showSymbol: false,
      lineStyle: {
        width: 2,
      },
      itemStyle: {
        color: colorList[0],
        borderColor: colorList[0],
      },
    },
    {
      name: "Nike",
      type: "line",
      data: [],
      symbolSize: 1,
      symbol: "circle",
      smooth: true,
      yAxisIndex: 0,
      showSymbol: false,
      lineStyle: {
        width: 2,
      },
      itemStyle: {
        color: colorList[1],
        borderColor: colorList[1],
      },
    },
  ],
};
export function handle(text, name1, name2, num, monthDatas) {
  option.title.text = text;
  option.series[0].name = name1;
  option.series[1].name = name2;

  const arr = [];
  const arr1 = [];
  const arr2 = [];
  for (let key in monthDatas.value) {
    let item = monthDatas.value[key];
    arr.push(key);
    if (num === 1) {
      arr1.push(amend(item.totalPrice));
      arr2.push(amend(item.paidPrice));
    }
    if (num === 2) {
      arr1.push(item.waterUnitCount);
      arr2.push(item.needPayWaterUnitCount);
    }
  }

  option.xAxis.data = arr;
  option.series[0].data = arr1;
  option.series[1].data = arr2;

  return JSON.parse(JSON.stringify(option));
}

export function handle3(text, name1, name2, monthAddedDatas, monthDatas) {
  option.title.text = text;
  option.series[0].name = name1;
  option.series[1].name = name2;

  const arrX = [];
  const arrY = [];
  for (let key in monthDatas.value) {
    let item = monthDatas.value[key];
    arrX.push(key);
    let num = amend(
      add(item.areaTotalBalance),
      add(monthAddedDatas.value[key].areaTotalBalance),
      "+"
    );

    arrY.push(num);
  }
  option.xAxis.data = arrX;
  option.series[0].data = arrY;
  option.series[1].data = [];
  return JSON.parse(JSON.stringify(option));
}

function add(obj) {
  if (obj) {
    let num = 0;
    for (let key in obj) {
      num = amend(num, obj[key], "+");
    }
    return num;
  } else {
    return 0;
  }
}
