import { amend } from "@/utils/func.js";

const option = {
  title: {
    text: "{name|" + 111 + "}\n{val|" + 22 + "}",
    top: "center",
    left: "center",
    textStyle: {
      rich: {
        name: {
          fontSize: 14,
          fontWeight: "normal",
          color: "#000",
          padding: [10, 0],
        },
        val: {
          fontSize: 16,
          fontWeight: "bolder",
          color: "#000",
        },
      },
    },
  },

  tooltip: {
    trigger: "item",
    formatter: "{b}: {d}% {c}",
    backgroundColor: "rgba(47,37,198,0.75)",
    borderColor: "#2880FF",
    borderWidth: 1,
    textStyle: {
      color: "#fff",
    },
  },
  series: [
    {
      name: "Nightingale Chart",
      type: "pie",
      radius: ["45%", "60%"],
      center: ["50%", "50%"],
      selectedOffset: 30,
      selectedMode: true,
      itemStyle: {
        borderRadius: 8,
      },
      data: [],
      labelLine: {
        // length: 40,
        // length2: 40,
      },
      label: {
        formatter: (params) => {
          return (
            "{icon|●}{name|" +
            params.name +
            "}\n{value|" +
            params.value +
            "   " +
            params.percent +
            "%" +
            "}"
          );
        },
        rich: {
          icon: {
            fontSize: 16,
            color: "inherit",
          },
          name: {
            fontSize: 16,
            padding: [0, 0, 0, 10],
            color: "#000",
          },
          value: {
            fontSize: 14,
            fontWeight: "bolder",
            padding: [10, 0, 0, 20],
            color: "inherit",
          },
        },
      },
    },
  ],
};

function changeOption(name, total, data) {
  option.series[0].data = data;
  if (name == "区域用水统计(吨)") {
    option.tooltip.formatter = "{b}:  {c}t  （{d}%）";
    option.title.text = "{name|" + name + "}\n{val|" + total + "}";
  } else {
    option.tooltip.formatter = "{b}:￥{c}  （{d}%）";
    option.title.text = "{name|" + name + "}\n{val|" + amend(total) + "}";
  }
  return JSON.parse(JSON.stringify(option));
}

export function getAreaData(
  num = 1,
  name,
  monthDatas,
  areaObj,
  monthAddedDatas,
  key1,
  key2
) {
  let total = 0;
  let data = [];
  let obj = {};

  function add(dateDatas) {
    for (let key in dateDatas.value) {
      let item = dateDatas.value[key];
      total = amend(total, item[key1], "+");

      let item2 = dateDatas.value[key][key2];

      for (let key2 in item2) {
        if (obj[key2]) {
          obj[key2] = amend(obj[key2], item2[key2], "+");
        } else {
          obj[key2] = item2[key2];
        }
      }
    }
  }

  if (num === 1 || num === 2) {
    add(monthDatas);
  }
  if (num === 1 || num === 3) {
    add(monthAddedDatas);
  }
  for (let key in obj) {
    if (name == "区域用水统计(吨)") {
      data.push({ value: obj[key], name: areaObj[key].name });
    } else {
      data.push({ value: amend(obj[key]), name: areaObj[key].name });
    }
  }

  return changeOption(name, total, data);
}

export function fn(num = 1, name, monthDatas, monthAddedDatas) {
  let total = 0;
  let data = [];
  let data1 = 0;
  let data2 = 0;

  function add(obj) {
    for (let key in obj.value) {
      let item = obj.value[key];
      total = amend(total, item.totalPrice, "+");
      data1 = amend(data1, item.paidPrice, "+");
    }
  }
  if (num === 1 || num === 2) {
    add(monthDatas);
  }
  if (num === 1 || num === 3) {
    add(monthAddedDatas);
  }
  data2 = amend(total, data1, "-");

  data.push({ value: amend(data1), name: "已交" });
  data.push({ value: amend(data2), name: "未交" });

  return changeOption(name, total, data);
}

