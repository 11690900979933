import { amend } from "@/utils/func.js";

const option = {
  title: {
    text: "{name|" + 111 + "}\n{val|" + 22 + "}",
    top: "center",
    left: "center",
    textStyle: {
      rich: {
        name: {
          fontSize: 14,
          fontWeight: "normal",
          color: "#000",
          padding: [10, 0],
        },
        val: {
          fontSize: 16,
          fontWeight: "bolder",
          color: "#000",
        },
      },
    },
  },

  tooltip: {
    trigger: "item",
    formatter: "{b}: {d}% {c}",
    backgroundColor: "rgba(47,37,198,0.75)",
    borderColor: "#2880FF",
    borderWidth: 1,
    textStyle: {
      color: "#fff",
    },
  },
  series: [
    {
      name: "Nightingale Chart",
      type: "pie",
      radius: ["45%", "60%"],
      center: ["50%", "50%"],
      selectedOffset: 30,
      selectedMode: true,
      itemStyle: {
        borderRadius: 8,
      },
      data: [
        { value: 40, name: "现金管理" },
        { value: 38, name: "固定收益" },
        { value: 32, name: "保险产品" },
        { value: 30, name: "混合策略" },
        { value: 28, name: "权益投资" },
        { value: 26, name: "另类投资" },
      ],
      labelLine: {
        // length: 40,
        // length2: 40,
      },
      label: {
        formatter: (params) => {
          return (
            "{icon|●}{name|" +
            params.name +
            "}\n{value|" +
            params.value +
            "   " +
            params.percent +
            "%" +
            "}"
          );
        },
        rich: {
          icon: {
            fontSize: 16,
            color: "inherit",
          },
          name: {
            fontSize: 16,
            padding: [0, 0, 0, 10],
            color: "#000",
          },
          value: {
            fontSize: 14,
            fontWeight: "bolder",
            padding: [10, 0, 0, 20],
            color: "inherit",
          },
        },
      },
    },
  ],
};

function changeOption(name, total, data) {
  option.title.text = "{name|" + name + "}\n{val|" + amend(total) + "}";
  option.series[0].data = data;
  option.tooltip.formatter = "{b}:￥{c}  （{d}%）";
  return JSON.parse(JSON.stringify(option));
}

export function getAreaData2(name, monthDatas, areaObj, key1, key2) {
  let total = 0;
  let data = [];
  let obj = {};

  function add(dateDatas) {
    for (let key in dateDatas.value) {
      let item = dateDatas.value[key];
      total = amend(total, item[key1], "+");

      let item2 = dateDatas.value[key][key2];

      for (let key2 in item2) {
        if (obj[key2]) {
          obj[key2] = amend(obj[key2], item2[key2], "+");
        } else {
          obj[key2] = item2[key2];
        }
      }
    }
  }

  add(monthDatas);

  for (let key in obj) {
    data.push({ value: amend(obj[key]), name: areaObj[key].name });
  }

  return changeOption(name, total, data);
}

export function total(name, monthDatas) {
  const xAxisName = [];
  let alipayCount = 0;
  let wechatCount = 0;
  for (let key in monthDatas.value) {
    let item = monthDatas.value[key];
    xAxisName.push(key);
    alipayCount = amend(item.alipayCount, alipayCount, "+");
    wechatCount = amend(item.wechatCount, wechatCount, "+");
  }

  option.series[0].data = [
    {
      value: amend(alipayCount),
      name: "支付宝",
    },
    {
      value: amend(wechatCount),
      name: "微信",
    },
  ];
  option.title.text =
    "{name|" +
    name +
    "}\n{val|" +
    amend(amend(alipayCount), amend(wechatCount), "+") +
    "}";
  return JSON.parse(JSON.stringify(option));
}

